<template>

  <div>

    <b-row>
      <b-col md="7">
        <b-card
          class="mb-0"
        >

          <b-row>
            <b-col md="12">
              <span>Uyumdan gelen verilerin son güncellenme zamanları</span>
              <hr>
              <b-list-group>
                <b-list-group-item
                  v-for="(item,index) in mailFileImportsData"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                >{{ item.description }}
                  <span>
                    {{ dateFormat(item.last_update) }}
                  </span></b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>

        </b-card>
      </b-col>

    </b-row>

  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BCard, BRow, BCol, VBModal, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import monitoringStoreModule from './monitoringStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
    }
  },
  methods: {
    dateFormat(date) { return this.$format_datetime(date) },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-monitoring'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, monitoringStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const mailFileImportsData = ref([])
    store.dispatch('app-monitoring/fetchMailFileImportsData', {})
      .then(response => {
        const { data } = response.data
        mailFileImportsData.value = data
      })
      .catch(() => {
        alert('Bilgiler getirilirken hata oluştu')
      })

    return {
      mailFileImportsData,
      snowOption: {
        theme: 'snow',
      },
    }
  },
}
</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
#add-new-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
