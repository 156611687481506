import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchMailFileImportsData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/mail-imported-file-history', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
